<template>
  <div id="company">
    <b-row>
      <b-col sm="8">
        <div class="card">
          <div slot="header" class="card-header">
            <i class="icon-bubble"></i>
            <span v-if="$route.params.id == '-1'">Stellenangebot anlegen</span>
            <span v-else>Stellenangebot bearbeiten</span>
            <small class="ml-3"> <strong> Unternehmen: {{ $route.query.company }}</strong></small>
          </div>
          <div v-if="company" class="card-body">
            <b-form v-on:submit.prevent="onSubmit" novalidate>

              <ShowOffer
                  v-if="company"
                  @openImageSelection="openImageSelection($event)"
                  @openMediaSelection="openMediaSelection($event)"

                  :standorts="company.standorts"
              ></ShowOffer>

              <div class="footer-btn" slot="footer" style="margin: 10px;">
                <b-button type="submit" stacked size="sm" variant="primary"><i class="fa fa-save"></i>
                  Speichern
                </b-button>&nbsp;
                <b-button size="sm" href="#" @click="$router.go(-1)" variant="danger"><i class="fa fa-ban"></i>
                  Abbrechen
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </b-col>

      <MediaSelection
          :show="showMediaSelection"
          :type="mediaType"
          :mime-types="'image/jpeg,image/png'"
          @close="showMediaSelection = false"
          @save="saveMedia($event)"
      />
    </b-row>
  </div>
</template>

<script>

import Vue from 'vue'
import MediaSelection from '../Media/MediaSelection'
import ShowOffer from './ShowOffer'
import {mapGetters} from 'vuex'
import VueNotifications from "vue-notifications";
import {quillEditor} from 'vue-quill-editor'

// require styles fpr quillEditor
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

const axios = require('axios')

export default {
  components: {
    MediaSelection,
    quillEditor,
    ShowOffer
  },
  name: 'EditOffer',
  data() {
    return {
      showMediaSelection: false,
      showVideoSelection: false,
      mediaType: '',
      tabIndex: 0,
      tabErrors: {},
      customToolbar: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': 1}, {'header': 2}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'color': []}],
            [{'align': []}],
            ['link']
          ]
        }
      },
      idx: 0,
      offerId: null,
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompany',
      offer: 'company/getOffer',
      error: 'company/getError'
    }),

  },
  created() {
    this.offerId = this.$route.query.offer
  },
  mounted() {
    this.$store.dispatch('company/loadCompany', this.$route.params.id)
    this.$store.dispatch('company/loadOffer', this.offerId).then(() => {
      // if(this.offerId == -1) {
      //     if (!this.company.stellenangebotes) {
      //         this.company.stellenangebotes = []
      //     }
      //     let offer = {
      //         content: []
      //     }
      //     Vue.set(this.company.stellenangebotes, this.company.stellenangebotes.length, offer)
      //     this.offerId = this.company.stellenangebotes.length - 1
      // }

    })

  },
  methods: {
    onSubmit() {
      this.$store.dispatch('company/saveOffer').then(
          response => {
            if (response.status == 200 || response.status == 201) {
              this.notifySaveSuccess()
            }
          }, error => {
            this.notifySaveError()
          }
      )
    },
    openImageSelection: function (payload) {
      this.showMediaSelection = true
      this.showVideoSelection = false
      this.mediaType = payload.mediaType
      this.mimeType = 'image/jpeg,image/png'
      this.idx = payload.idx
    },
    saveMedia: function (payload) {
      switch (this.mediaType) {
        case 'image':
          Vue.set(this.company, 'image', {
            'id': payload.id,
            'uid': payload.uid
          })
          break
        case 'offer':
          Vue.set(this.offer, 'image', {
            'id': payload.id,
            'uid': payload.uid
          })
          break
        case 'image_bg':
          Vue.set(this.company, 'image_bg', {
            'id': payload.id,
            'uid': payload.uid
          })
          break
        case 'imageWhiteLogo':
          Vue.set(this.company, 'imageWhiteLogo', {
            'id': payload.id,
            'uid': payload.uid
          })
          break
        default:
          switch (this.offer.content[this.blogEleIdx].columns[this.columnIdx].contentElement.type) {
            case 'poi':
            case 'question':
            case 'video':
              this.offer.content[this.blogEleIdx].columns[this.columnIdx].contentElement.content.image = {
                'id': payload.id,
                'uid': payload.uid
              }
              break;
            case 'image_gallery':
              this.offer.content[this.blogEleIdx].columns[this.columnIdx].contentElement.content.push({
                'id': payload.id,
                'uid': payload.uid
              })
              break;
            default:
              this.offer.content[this.blogEleIdx].columns[this.columnIdx].contentElement.content = {
                'id': payload.id,
                'uid': payload.uid
              }
          }
          break
      }
    },
    deleteMedia: function (mediaType) {
      this.company.image = ''
    },
    openMediaSelection: function (payload) {
      this.showMediaSelection = true
      this.mediaType = payload.mediaType
      this.mimeType = 'image/jpeg,image/png'
      this.blogEleIdx = payload.blogEleIdx
      this.columnIdx = payload.columnIdx
    },
  },

  notifications: {
    notifySaveSuccess: {
      type: VueNotifications.types.success,
      title: 'Unternehmen wurde erfolgreich gespeichert',
    },
    notifySaveError: {
      type: VueNotifications.types.error,
      title: 'Fehler beim speichern des Unternehmens'
    },
    notifyAngeboteRelated: {
      type: VueNotifications.types.error,
      title: 'It is not possible to remove this Stelle because it is related to a certain Stellenangebote'
    }
  }
}
</script>
