<template>
  <b-col md="12">
    <div class="blog-element-box">
      <div slot="header" class="card-header border-solid-lightgrey">
        <span v-if="blogElement.columns.length == 1">Einspalter</span>
        <span v-if="blogElement.columns.length == 2">Zweispalter</span>

        <button type="button" class="btn btn-primary btn-sm float-right" @click="removeBlogElement(blogEleIdx)">
          <span class="fa fa-remove"></span>
        </button>
        <button v-if="blogEleIdx > 0" type="button" class="btn btn-primary btn-sm float-right"
                @click="moveBlogElement(blogEleIdx, 'up')">
          <span class="icon-arrow-up-circle"></span>
        </button>
        <button v-if="blogEleIdx < nrOfBlogElements-1" type="button" class="btn btn-primary btn-sm float-right"
                @click="moveBlogElement(blogEleIdx, 'down')">
          <span class="icon-arrow-down-circle"></span>
        </button>
        <div class="float-right margin-right-20">
          <c-switch class="mx-1 switch-sm" color="primary" v-model="blogElement.indent" :id="'enabled_' + blogEleIdx"
                    variant="3d"/>
          <label :for="'enabled_' + blogEleIdx" class="switch--label">Einzug</label>
        </div>
      </div>

      <b-row>
        <b-col v-for="(column, index) in blogElement.columns" :key="index" :md="column.size">
          <div class="card-body border-solid-lightgrey">
            <div v-if="!column.contentElement.type">
              <button v-for="e in elements" type="button" class="btn btn-primary btn-sm margin-right-5" @click="addElem( e.key, index)">
                <span :class="'fa fa-' + e.icon"></span> {{ e.label }}
              </button>
            </div>
            <div class="margin-top-10" v-else>
              <h5 class="mb-3" v-if="column.contentElement && column.contentElement.type == 'table'">Bsp: Spalte1;
                Spalte2</h5>
              <h4 class="mb-3" v-if="column.contentElement && column.contentElement.type == 'benefits'"><i
                  class="fa fa-star"/> Benefits werden hier wiedergegeben</h4>
              <h4 class="mb-3" v-if="column.contentElement && column.contentElement.type == 'mobile-sidebar'"><i
                  class="fa fa-mobile"/> Sidebar wird mobile hier angezeigt</h4>
              <quill-editor
                  v-if="column.contentElement && (column.contentElement.type == 'text'  || column.contentElement.type == 'table')"
                  :name="'block' + index" v-model="column.contentElement.content.value"
                  :options="customToolbarText"></quill-editor>
              <quill-editor v-if="column.contentElement && column.contentElement.type == 'bulletList'"
                            :name="'block' + index" v-model="column.contentElement.content.value"
                            :options="customToolbarList"></quill-editor>
              <div v-if="column.contentElement && column.contentElement.type == 'image'" :name="'block' + index">
                <img v-if="column.contentElement.content.id > 0"
                     v-bind:src="$store.state.mediaServiceUrl + column.contentElement.content.uid + '/150/150'"
                     class="image-placeholder-150"><br/>
                <b-button v-if="column.contentElement && column.contentElement.type == 'image'" size="sm"
                          @click="openMediaSelection(blogEleIdx, index)" variant="dark" class="margin-top-10"><i
                    class="fa"></i>Bild auswählen/ändern
                </b-button>&nbsp;
                <a class="fa fa-trash fa-lg text-primary"
                   v-if="column.contentElement && column.contentElement.type == 'image' && column.contentElement.content && column.contentElement.content.id > 0"
                   @click="deleteMedia(index)"></a>
                <div class="margin-10">
                  <b-form-group>
                    <label for="subheading">Subheading</label>
                    <b-form-input type="text" id="subheading" v-model="column.contentElement.content.subheading"
                                  placeholder="Subheading"></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <label for="link">Link</label>
                    <b-form-input type="text" id="link" v-model="column.contentElement.content.link"
                                  placeholder="Link"></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div v-if="column.contentElement && column.contentElement.type == 'spacer'" :name="'block' + index">
                <b-form-group>
                  <label for="spacerLength">Length</label>
                  <b-form-input type="number" id="spacerLength" min="0" v-model="column.contentElement.content.length"
                                placeholder="Spacer length"></b-form-input>
                </b-form-group>
              </div>
              <div v-if="column.contentElement && column.contentElement.type == 'image_gallery'"
                   :name="'block' + index">
                <b-col v-for="(image, imageIdx) in column.contentElement.content" :key=" index + '_' + imageIdx" md="4">
                  <b-form-group>
                    <img v-if="image.id > 0" v-bind:src="$store.state.mediaServiceUrl + image.uid + '/150/150'"
                         class="image-placeholder-150"><br/>
                    <a class="fa fa-trash fa-lg text-primary" @click="deleteMediaForGallery(index, imageIdx)"></a>
                  </b-form-group>
                </b-col>
                <b-button v-if="column.contentElement && column.contentElement.type == 'image_gallery'" size="sm"
                          @click="openMediaSelection(blogEleIdx, index)" variant="dark" class="margin-top-10"><i
                    class="fa"></i>Bild auswählen/ändern
                </b-button>&nbsp;
              </div>
              <div v-if="column.contentElement && column.contentElement.type == 'quote'" :name="'block' + index">
                <b-form-group>
                  <label for="text">Quote</label>
                  <b-form-input type="text" v-model="column.contentElement.content.text"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="author">Author</label>
                  <b-form-input type="text" id="author" v-model="column.contentElement.content.author"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="sub_author">Subspace Author</label>
                  <b-form-input type="text" id="sub_author"
                                v-model="column.contentElement.content.sub_author"></b-form-input>
                </b-form-group>
              </div>
              <div v-if="column.contentElement && column.contentElement.type == 'question'" :name="'block' + index">
                <b-form-group>
                  <label for="text">Question text</label>
                  <b-form-input type="text" v-model="column.contentElement.content.text"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="text">Body text</label>
                  <quill-editor :name="'question-block'" v-model="column.contentElement.content.body"
                                :options="customToolbarText"></quill-editor>
                </b-form-group>
                <b-form-group>
                  <label for="question_link">Link button</label>
                  <b-form-input type="text" id="question_link"
                                v-model="column.contentElement.content.question_link"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="question_image">Image</label>
                  <br/>
                  <img v-if="column.contentElement.content.image && column.contentElement.content.image.id > 0"
                       v-bind:src="$store.state.mediaServiceUrl + column.contentElement.content.image.uid + '/150/150'"
                       class="image-placeholder-150"><br/>
                  <b-button size="sm" @click="openMediaSelection(blogEleIdx, index)" variant="dark"
                            class="margin-top-10"><i class="fa"></i>Bild auswählen/ändern
                  </b-button>
                </b-form-group>
              </div>
              <div v-if="column.contentElement && column.contentElement.type === 'video'" :name="'block' + index">
                <b-form-group>
                  <label for="text">Video Link/ID</label>
                  <b-form-input type="text" v-model="column.contentElement.content.link"></b-form-input>
                  <br/>
                  <img v-if="column.contentElement.content.image && column.contentElement.content.image.id > 0"
                       v-bind:src="$store.state.mediaServiceUrl + column.contentElement.content.image.uid + '/150/150'"
                       class="image-placeholder-150"><br/>
                  <b-button size="sm" @click="openMediaSelection(blogEleIdx, index)" variant="dark"
                            class="margin-top-10"><i class="fa"></i>Bild auswählen/ändern
                  </b-button>
                </b-form-group>
              </div>
              <div v-if="column.contentElement && column.contentElement.type == 'table'" class="mt-3">
                <b-form-group>
                  <b-form-checkbox class="mb-2 mr-sm-2 mb-sm-0" v-model="column.contentElement.content.tableheadEnabled">
                    Erste Zeile als Kopfzeile
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-col>
</template>


<script>

import {quillEditor} from 'vue-quill-editor'
import {Switch as cSwitch} from '@coreui/vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor,
    cSwitch
  },
  name: 'BlogElement',
  props: {
    columns: 0,
    blogElement: {},
    blogEleIdx: 0,
    nrOfBlogElements: 0,
  },
  data() {
    return {
      elements: [
        { label: 'Text', icon: 'file-text', key: 'text'},
        { label: 'Tabelle', icon: 'file-text', key: 'table'},
        { label: 'Img', icon: 'image', key: 'image'},
        { label: '', icon: 'arrows-v', key: 'spacer'},
        { label: 'Gallery', icon: 'image', key: 'image_gallery'},
        { label: 'Zitat', icon: 'quote-right', key: 'quote'},
        { label: 'Kontakt-Box', icon: 'question', key: 'question'},
        { label: 'Video', icon: 'video-camera', key: 'video'},
        { label: 'Benefits', icon: 'star', key: 'benefits'},
        { label: 'Mobil Sidebar', icon: 'mobile', key: 'mobile-sidebar'},
      ],
      customToolbarText: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4]}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'color': []}],
            [{'align': []}],
            ['link']
          ]
        }
      },
      customToolbarList: {
        modules: {
          toolbar: [
            [{'list': 'ordered'}, {'list': 'bullet'}],
          ]
        }
      },
      showMediaSelection: false,
      mediaType: '',
    }
  },
  methods: {
    removeBlogElement(index) {
      this.$emit('remove', {index: index})
    },
    moveBlogElement(index, direction) {
      this.$emit('move', {index: index, direction: direction})
    },
    addElem(elemType, colIndex) {
      switch (elemType) {
        case 'table':
          this.$props.blogElement.columns[colIndex].contentElement = {type: elemType, content: {'value': ''}, tableheadEnabled: false}
          break
        case 'text':
        case 'bulletList':
          this.$props.blogElement.columns[colIndex].contentElement = {type: elemType, content: {'value': ''}}
          break
        case 'image':
          this.$props.blogElement.columns[colIndex].contentElement = {type: elemType, content: {'id': '', 'uid': ''}}
          break
        case 'spacer':
          this.$props.blogElement.columns[colIndex].contentElement = {
            type: elemType,
            content: {'length': ''}
          }
          break
        case 'image_gallery':
          this.$props.blogElement.columns[colIndex].contentElement = {
            type: elemType,
            content: []
          }
          break
        case 'quote':
          this.$props.blogElement.columns[colIndex].contentElement = {
            type: elemType,
            content: {
              text: '',
              author: '',
              sub_author: ''
            }
          }
          break
        case 'question':
          this.$props.blogElement.columns[colIndex].contentElement = {
            type: elemType,
            content: {
              text: '',
              question_link: '',
              body: '',
              image: {'id': '', 'uid': ''}
            }
          }
          break
        case 'video':
          this.$props.blogElement.columns[colIndex].contentElement = {
            type: elemType,
            content: {
              link: '',
              image: {'id': '', 'uid': ''}
            }
          }
          break
        case 'benefits':
        case 'mobile-sidebar':
          this.$props.blogElement.columns[colIndex].contentElement = {
            type: elemType,
          }
          break
      }
    },
    openMediaSelection: function (blogEleIdx, columnIdx) {
      this.$emit('openMediaSelection', {blogEleIdx: blogEleIdx, columnIdx: columnIdx})
    },
    deleteMedia: function (columnIdx) {
      this.$props.blogElement.columns[columnIdx].contentElement.content = {'id': '', 'uid': ''}
    },
    deleteMediaForGallery: function (columnIdx, imageIdx) {
      this.$props.blogElement.columns[columnIdx].contentElement.content.splice(imageIdx, 1)
    }
  }
}

</script>
<style>
/*.card-header{height: 200px;}*/
</style>
