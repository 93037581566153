//../src/Chart.js
import {
    Radar,
    mixins
} from 'vue-chartjs'

export default {
    extends: Radar,
    mixins: [mixins.reactiveProp],
    props: {
        'chartData': {
            type: Object
        }
    },
    data() {
        return {
            options: {
                scale: {
                    ticks: {
                        beginAtZero: true,
                        max: 10,
                        min: 0,
                        stepSize: 1
                    },
                    angleLines: {
                        display: true
                    },
                    // suggestedMin: 50,
                    // suggestedMax: 100
                },
                responsive: true,
                legend: {
                    display: false
                },
            }
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options)
    }

}