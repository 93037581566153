<template>
  <div>

    <b-tabs>
      <b-tab>
        <template slot="title">
          <i class="fa fa-life-bouy"></i> Stammdaten
        </template>

        <b-col md="10">
          <b-form-group>
            <label for="name">Name</label>
            <b-form-input :state="offer.name? null:false" type="text" id="title"
                          v-model="offer.name" placeholder="Name"></b-form-input>
          </b-form-group>
          <b-form-group>
            <label for="slug">Slug</label>
            <b-form-input type="text" id="slug" v-model="offer.slug"
                          placeholder="Leer lassen um automatisch Slug zu generieren"></b-form-input>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label for="sales-region">Standort</label>
                <b-form-select v-model="offer.companyLocation" data-required="true"
                               :state="offer.companyLocation == null? false : null">
                  <option v-for="(option, idx) in standortOptions"
                          :key="idx"
                          :value="option.id">
                    {{ option.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <label>Locations</label>
              <v-select v-model="offer.locations" multiple :close-on-select="false" label="text"
                        :options="locationOptions"
                        placeholder="Bitte wählen"></v-select>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col md="12">
              <h4>Filtereigenschaften</h4>
            </b-col>
            <b-col md="6">
              <label :class="offer.branch? '' : 'text-danger'">Branche *</label>
              <SelectProperty
                  :close-on-select="true"
                  no-label
                  :horizontal="{ label: 'col-sm-12', input: 'col-sm-12'}"
                  v-model="offer.branch"
                  :property="'branch'"
              />
            </b-col>
            <b-col md="6">
              <label :class="offer.minGraduation? '' : 'text-danger'">Erforderlicher Schulabschluss *</label>
              <SelectProperty
                  :load-options="false"
                  :close-on-select="true"
                  no-label
                  :horizontal="{ label: 'col-sm-12', input: 'col-sm-12'}"
                  v-model="offer.minGraduation"
                  :property="'graduation'"
              />
            </b-col>
            <b-col md="6">
              <label :class="offer.departments? '' : 'text-danger'">Fachbereich *</label>
              <SelectProperty
                  :load-options="false"
                  :close-on-select="true"
                  no-label
                  :horizontal="{ label: 'col-sm-12', input: 'col-sm-12'}"
                  v-model="offer.departments"
                  :property="'department'"
                  multiple
              />
            </b-col>
            <b-col md="6">
              <label :class="offer.beginningPeriod? '' : 'text-danger'">Zeitraum des Ausbildungsstart
                *</label>
              <SelectProperty
                  :load-options="false"
                  :close-on-select="true"
                  no-label
                  :horizontal="{ label: 'col-sm-12', input: 'col-sm-12'}"
                  v-model="offer.beginningPeriod"
                  :property="'begin-period'"
              />
            </b-col>
          </b-row>
          <hr/>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label for="email">E-Mail</label>
                <b-form-input type="text" id="email" v-model="offer.email"
                              placeholder="Email"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="url">Link</label>
                <b-form-input type="text" id="url" v-model="offer.url"
                              placeholder="URL"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label for="created_at">Erstellt am</label>
                <b-form-input type="date" id="created_at" v-model="offer.createdAt" placeholder="Posted At"
                              :state="offer.createdAt? null:false"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="begins_at">Frühester Beginn</label>
                <b-form-input type="date" id="begins_at" v-model="offer.beginsAt" placeholder="Begins At"
                              :state="offer.beginsAt? null:false"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label for="duration">Dauer</label>
                <b-form-input type="text" id="duration" v-model="offer.duration"
                              placeholder="Duration"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label for="videoId">Vimeo Video ID</label>
                <b-form-input type="text" id="videoId" v-model="offer.videoId"
                              placeholder="z.B. 76979871"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group>
            <b-row>
              <b-col md="5">
                <label>Bild</label><br>
                <div class="margin-bottom-10">
                  <img v-if="offer.image && offer.image.id > 0"
                       v-bind:src="$store.state.mediaServiceUrl + offer.image.uid + '/200/200'"
                       class="image-placeholder-150">
                </div>
                <b-button size="sm" @click="openImageSelection(angeboteIdx)" variant="dark"><i
                    class="fa"></i>Bild auswählen/ändern
                </b-button>&nbsp;
                <a class="fa fa-trash fa-lg text-primary"
                   v-if="offer.image && offer.image.id" @click="deleteImage('image')"></a>

              </b-col>
            </b-row>
          </b-form-group>

        </b-col>
      </b-tab>

      <b-tab>
        <template slot="title">
          <i class="fa fa-star"></i> Benefits
        </template>

        <b-row>
          <b-col>
            <b-form inline>
              <label class="ml-2 mr-3" for="icon_key">Icon</label>
              <b-form-select :options="benefitIconOptions" v-model="benefit.icon_key"/>
              <b-button type="button" stacked size="sm" variant="outline" color="primary"
                        @click="offer.benefits.push(benefit), benefit = {}" class="ml-3 mr-1 btn-secondary"
                        :disabled="!(benefit.icon_key)"
              ><i class="fa fa-plus-square"></i>
                Hinzufügen
              </b-button>&nbsp;

            </b-form>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12" v-if="offer">
            <v-client-table v-if="offer.benefits.length > 0" ref="table" :api-mode="false"
                            :data="offer.benefits" :columns="['icon_key', 'actions']"
                            :options="options">
              <template slot="actions" slot-scope="props">
                <b-button v-if="props.index != offer.benefits.length" size="sm" variant="secondary"
                          class="px-4" @click="moveFromArray(props, 'benefits', 'down')"><i
                    class="fa fa-long-arrow-down"></i></b-button>
                <b-button v-if="props.index != 1" size="sm" class="px-4"
                          @click="moveFromArray(props, 'benefits', 'up')"><i class="fa fa-long-arrow-up"></i></b-button>
                <b-button size="sm" color="danger" variant="secondary" class="px-4"
                          @click="removeFromArray(props, 'benefits')"><i class="fa fa-trash"></i></b-button>
              </template>
            </v-client-table>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template slot="title">
          <i class="fa fa-file-text"></i> Inhalt
        </template>
        <b-row>
          <b-col>
            <b-row>
              <b-col md="12">
                <b-button @click="addBlock(1, '12')" class="margin-right-5 mb-1" size="sm" variant="dark"><i
                    class="fa fa-plus"></i> Einspalter
                </b-button>
                <b-button @click="addBlock(2, '6/6')" class="margin-right-5 mb-1" size="sm" variant="dark"><i
                    class="fa fa-plus"></i> Zweispalter (50/50)
                </b-button>
                <b-button @click="addBlock(2, '4/8')" class="margin-right-5 mb-1" size="sm" variant="dark"><i
                    class="fa fa-plus"></i> Zweispalter (33/66)
                </b-button>
                <b-button @click="addBlock(2, '8/4')" class="margin-right-5 mb-1" size="sm" variant="dark"><i
                    class="fa fa-plus"></i> Zweispalter (66/33)
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <template v-if="offer">
          <b-row v-for="(blogElement, index) in offer.content" :key="index">
            <BlogElement
                :blogElement="blogElement"
                :blogEleIdx="index"
                :nrOfBlogElements="offer.content.length"
                @remove="removeBlogElement($event)"
                @move="moveBlogElement($event)"
                @openMediaSelection="openMediaSelection($event)"
            />
          </b-row>
        </template>
      </b-tab>
      <b-tab>
        <template slot="title">
          <i class="fa fa-bar-chart"></i> Matching
        </template>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label for="SocialCompetence">SocialCompetence</label>
              <b-form-input max="10" min="0" step="1" type="number" id="SocialCompetence" v-model="offer.socialCompetence"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="Stability">Stability</label>
              <b-form-input max="10" min="0" step="1"type="number" id="SocialCompetence" v-model="offer.stability"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="PracticalThinking">PracticalThinking</label>
              <b-form-input max="10" min="0" step="1"type="number" id="PracticalThinking" v-model="offer.practicalThinking"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="Responsibility">Responsibility</label>
              <b-form-input max="10" min="0" step="1"type="number" id="Responsibility" v-model="offer.responsibility"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="StructuredThinking">StructuredThinking</label>
              <b-form-input max="10" min="0" step="1"type="number" id="StructuredThinking" v-model="offer.structuredThinking"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="Determination">Determination</label>
              <b-form-input max="10" min="0" step="1"type="number" id="Determination" v-model="offer.determination"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <chart :chartData="dataCollection" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {quillEditor} from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {Switch as cSwitch} from "@coreui/vue";
import Vue from "vue";

import {ClientTable} from 'vue-tables-2'
import MediaSelection from '../Media/MediaSelection'
import BlogElement from '../Blog/BlogElement'
import SelectProperty from '../Properties/SelectProperty'
import vSelect from "vue-select";
import {mapGetters} from "vuex";

import Chart from "./chart";

Vue.use(ClientTable)

export default {
  components: {
    MediaSelection,
    BlogElement,
    quillEditor,
    cSwitch,
    SelectProperty,
    Chart,
    vSelect
  },
  name: "ShowOffer",
  props: {
    angeboteIdx: 0,
    standorts: null,
  },
  data() {
    return {
      customToolbar: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': 1}, {'header': 2}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'color': []}],
            [{'align': []}],
            ['link']
          ]
        }
      },
      showMediaSelection: false,
      mediaType: '',
      options: {
        headings: {
          edit: '',
          url: 'Website'
        },
        filterable: false,
        texts: {
          //count: 'Showing {from} to {to} of {count} records|{count} records|One record - Test',
          count: '',
          filterPlaceholder: 'Suche...',
        },
        pagination: false,
      },

      benefit: {},
      benefitIconOptions: [
        {text: 'Weiterbildung/Talentförderung', value: '-education'},
        {text: 'Karrierechancen', value: '-career-opportunities'},
        {text: 'Teamevents', value: '-team-events'},
        {text: 'Flexibles Arbeitszeitmodell', value: '-flexible-working'},
        {text: 'Gesundheitsfördernde Maßnahmen', value: '-health'},
        {text: 'Betriebsarzt', value: '-company-doctor'},
        {text: 'Sportangebot', value: '-sports-offer'},
        {text: 'Onboarding-Programm', value: '-onboarding-programme'},
        {text: 'Gute Verkehrsanbindung', value: '-good-transport-connections'},
        {text: 'Parkplatz', value: '-parking-space'},
        {text: 'Arbeitskleidung', value: '-workwear'},
        {text: 'Jobfahrrad / Fahrradleasing', value: '-bicycle'},
        {text: 'Mobilität / Firmenauto', value: '-mobility-car'},
        {text: 'Home Office', value: '-home-office'},
        {text: 'Moderne Büros / Räumlichkeiten', value: '-modern-offices'},
        {text: 'Technische Ausstattung / Geräte', value: '-technical-equipment'},
        {text: 'Aufenthaltsraum / Pausengestaltung', value: '-recreation-room'},
        {text: 'Verpflegung', value: '-catering'},
        {text: 'Nachhaltigkeit', value: '-sustainability'},
        {text: 'Soziales Engagement', value: '-social-commitment'},
        {text: 'Finanzielle Zuschüsse', value: '-financial-subsidies'},
        {text: 'Agile Organisation', value: '-agile-organisation'},
        {text: 'Zusätzliche Urlaubstage', value: '-extra-holidays'},
        {text: 'Mitarbeiter-Rabatte', value: '-employee-discounts'},
        {text: 'Mitarbeiter-Geschenke', value: '-employee-gifts'},
      ],

      dataCollection: {}
    }
  },
  computed: {
    ...mapGetters({
      offer: 'company/getOffer',
    }),
    standortOptions: function () {
      let opt = []
      if (this.standorts) {
        for (let i = 0; i < this.standorts.length; i++) {
          if (this.standorts[i].id) {
            opt.push(this.standorts[i])
          }
        }
      }
      return opt
    },
    locationOptions() {
      let opt = []
      if (this.standorts) {
        for (let i = 0; i < this.standorts.length; i++) {
          if (this.standorts[i].id) {
            opt.push({
              value: this.standorts[i].id,
              text: this.standorts[i].name,
            })
          }
        }
      }
      return opt
    }
  },
  watch: {
    'offer': {
      deep: true,
      handler(val) {
        this.dataCollection = {
          labels: [
            "SocialCompetence",
            "Stability",
            "PracticalThinking",
            "Responsibility",
            "StructuredThinking",
            "Determination",
          ],
          datasets: [
            {
              label: "Assigned values",
              backgroundColor: "#f87979",
              data: [
                val.socialCompetence,
                val.stability,
                val.practicalThinking,
                val.responsibility,
                val.structuredThinking,
                val.determination,
              ]
            }
          ]
        }
        this.$forceUpdate()
      }
    }


  },
  methods: {
    moveFromArray(props, array, direction) {
      let element = props.row
      const idx = props.index - 1
      this.offer[array].splice(idx, 1)

      let newIndex = 0
      if (direction == 'up') {
        newIndex = idx - 1
      } else {
        newIndex = idx + 1;
      }
      this.offer[array].splice(newIndex, 0, element)
    },
    removeFromArray(props, array) {
      this.offer[array].splice(props.index - 1, 1)
    },
    // removeAngebote(index) {
    //   this.$emit('remove', {index: index})
    // },
    openImageSelection: function (idx) {
      this.$emit('openImageSelection', {idx: idx, mediaType: 'offer'})
    },
    deleteImage: function (idx) {
      this.offer.image = null
      this.$forceUpdate()
    },

    // Inhalt Administration
    addBlock(columns, width) {
      let widthList = width.split('/');
      let columnsList = []
      for (let i = 0; i < columns; i++) {
        columnsList.push({size: widthList[i], contentElement: {}})
      }
      let blogElement = {indent: true, columns: columnsList}
      Vue.set(this.offer.content, this.offer.content.length, blogElement)
    },
    removeBlogElement(payload) {
      this.offer.content.splice(payload.index, 1)
    },
    moveBlogElement(payload) {
      let blogEleToMove = this.offer.content[payload.index]
      this.offer.content.splice(payload.index, 1)

      let newIndex = 0
      if (payload.direction == 'up') {
        newIndex = payload.index - 1
      } else {
        newIndex = payload.index + 1;
      }
      this.offer.content.splice(newIndex, 0, blogEleToMove)
    },
    openMediaSelection: function (payload) {
      this.$emit('openMediaSelection', payload)
    },
  }
}
</script>

<style scoped>

</style>
